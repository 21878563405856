<div class="wrapper">
    <div *ngIf="lookupdata !== undefined" class="{{themeColor}}">
        <!-- navigation -->
        <app-header></app-header>

        <!-- side nav -->
        <app-admin-sidebar></app-admin-sidebar>

        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <section class="content">
                <div class="container-fluid">

                    <router-outlet></router-outlet>


                </div>
            </section>
        </div>
        <!-- /.content-wrapper -->

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->

        <!-- Main Footer -->
        <app-footer></app-footer>
    </div>
</div>
