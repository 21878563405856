<div id="form-metadata-controls" cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="templateControls !== undefined && templateControls.length > 0">
    <div class="panel-group card card-theme" *ngFor="let control of templateControls; let i = index;" cdkDrag>
        <div class="control" >
            <div class="panel panel-default inline-block">
                <div class="panel-heading" cdkDragHandle>
                    <h4 class="panel-title" >

                        <a class="btn btn-link" (click)="selectedformbasedonId(control)">
                        <svg class="bi bi-three-dots-vertical" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg" >
                            <path fill-rule="evenodd"
                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                        {{ control.name }} </a>
                    </h4>
                </div>
            </div>
        </div>
    </div>
</div>
