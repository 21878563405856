<div class="{{themeColor}}">
    <div id="registration" class="row-flex full-height registration-height center-xs middle-xs bg-theme  bg-splash">
        <header class="main-header">
            <div class="logo">
                <img class="logoimage" src="{{tenantLogo}}" *ngIf="tenantLogo !== ''">
                <span class="brand-text" *ngIf="tenantLogo === ''">{{tenantName}}</span>
            </div>
        </header>
        <div class="container">
            <form name="registrationForm" *ngIf="alreadyRegistered !== undefined && !alreadyRegistered && !confirmEmailSent"
                class="col-md-8 offset-md-2 col-xs-12" (ngSubmit)="submitRegister(registrationForm)"
                #registrationForm="ngForm">
                <div *ngIf="isOldBrowser" class="login-panel">
                    <i class="fa fa-warning pull-left" style="font-size: 42px;"></i>
                    <p>This application was built to support IE 10 and greater. </p>
                    <p>Please use IE 10 or greater, Edge, Firefox, or Chrome.</p>
                </div>
                <div class="card card-theme text-left" *ngIf="!isOldBrowser" [style.maxHeight.px]="screenHeight">
                    <div class="card-header with-border">
                        <h1 class="card-title">Register for {{tenantName}}</h1>
                    </div>
                    <div class="card-body p30">
                        <div class="alert-danger alert mb20" *ngIf="message">
                            <div class="notification-content" *ngIf="message">
                                {{message}}
                            </div>
                        </div>

                        <div class="form-group" [class.has-error]="userNameControl.invalid && userNameControl.touched">
                            <label     [ngClass]="{ 'invalid': userNameControl.invalid &&(userNameControl.touched || submitted)}">
                                Name*</label>
                            <input type="text" [(ngModel)]="user.name" name="name" class="form-control"
                            oninput="this.value = this.value.replace(/[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}|^ /g,'')"
                                #userNameControl="ngModel" required />
                            <span style="color:red" class="help-block"
                            *ngIf="userNameControl.errors?.required && (userNameControl.touched || submitted) ">
                                Name is Required
                            </span>
                        </div>
                        <div class="form-group"
                            [class.has-error]="userEmailControl.invalid && userEmailControl.touched">
                            <label  [ngClass]="{ 'invalid': userEmailControl.invalid &&(userEmailControl.touched || submitted)}">
                                Email* </label>
                            <input type="email" [(ngModel)]="user.email" name="email" #userEmailControl="ngModel"
                            (keydown.space)="$event.preventDefault();"
                                class="form-control" required email />
                            <span style="color:red" class="help-block"
                            *ngIf="userEmailControl.errors?.required &&  (userEmailControl.touched || submitted) ">
                                Email is Required
                            </span>
                            <span style="color:red" class="help-block"
                                *ngIf="userEmailControl.errors?.email && userEmailControl.touched">
                                Valid Email Address Required
                            </span>
                        </div>
                        <div class="form-group"
                            [class.has-error]="userPasswordControl.invalid && userPasswordControl.touched">
                            <label   [ngClass]="{ 'invalid': userPasswordControl.invalid &&(userPasswordControl.touched || submitted)}">
                                Password*</label>
                            <input name="password" class="form-control" type="password" [(ngModel)]="user.password"
                            oninput="this.value = this.value.replace(/[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}|^ /g,'')"
                                #userPasswordControl="ngModel" required>
                            <span style="color:red" class="help-block"
                            *ngIf="userPasswordControl.errors?.required && (userPasswordControl.touched || submitted)">
                                Password is Required
                            </span>
                        </div>
                        <div class="form-group" [class.has-error]="userConfirmPasswordControl.invalid && userConfirmPasswordControl.touched">
                            <label     [ngClass]="{ 'invalid': userConfirmPasswordControl.invalid &&(userConfirmPasswordControl.touched || submitted)}">
                                Confirm Password*</label>
                            <input name="confirmpassword" appConfirmEqualValidator="password" class="form-control"
                                type="password" [(ngModel)]="user.confirmPassword" #userConfirmPasswordControl="ngModel"
                                oninput="this.value = this.value.replace(/[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}|^ /g,'')"
                                required>
                            <span style="color:red" class="help-block"
                            *ngIf="userConfirmPasswordControl.errors?.required &&  (userConfirmPasswordControl.touched || submitted)">
                                Confirm Password is Required
                            </span>
                            <span style="color:red" class="help-block" *ngIf="userConfirmPasswordControl.errors?.notEqual && userConfirmPasswordControl.touched
                                        && !userConfirmPasswordControl.errors?.required">
                                Password and Confirm Password does not match
                            </span>
                        </div>
                        <div class="row" *ngIf="controls.length > 0">
                            <div [ngClass]="{'col-sm-6': control.type !== 'textarea', 'col-sm-12':control.type === 'textarea' }"
                                *ngFor="let control of controls; let i=index;" [ngSwitch]="control.type">

                                <div *ngSwitchCase="'signature'" class="blue">
                                    <div class="form-group">
                                        <label>{{control.label}}</label>
                                        <div class="signaturediv">
                                            <img class="img-responsive" src="{{control.value}}" *ngIf="control.value !== ''" />
                                            <button type="button" class="btn btn-primary" (click)="signature(control)">Sign</button>
                                        </div>

                                    </div>
                                </div>

                                <div *ngSwitchCase="'text'" class="blue">
                                    <app-text [control]="control" [index]="i"></app-text>
                                </div>

                                <div *ngSwitchCase="'label'" class="blue">
                                    <app-label [control]="control" [index]="i"></app-label>

                                </div>

                                <div *ngSwitchCase="'phone number'" class="blue">
                                    <app-phonenumber [control]="control" [index]="i"></app-phonenumber>
                                </div>

                                <div *ngSwitchCase="'signaturepad'" class="blue">
                                    <app-signature-pad [control]="control" [index]="i"></app-signature-pad>
                                </div>

                                <div *ngSwitchCase="'email'" class="blue">
                                    <app-email [control]="control" [index]="i"></app-email>
                                </div>

                                <div *ngSwitchCase="'datepicker'" class="blue">
                                    <app-datepicker [control]="control" [index]="i"></app-datepicker>
                                </div>

                                <div *ngSwitchCase="'timepicker'" class="blue">
                                    <app-timepicker [control]="control" [index]="i"></app-timepicker>
                                </div>
                                <div *ngSwitchCase="'checkboxlist'" class="blue">
                                    <app-checkboxlist [control]="control" [index]="i"></app-checkboxlist>
                                  </div>

                                <div *ngSwitchCase="'radiobuttonlist'" class="blue">
                                    <app-radiobuttonlist [control]="control" [index]="i"></app-radiobuttonlist>
                                </div>

                                <div *ngSwitchCase="'select'" class="blue">
                                    <app-select [control]="control" [index]="i"></app-select>
                                </div>

                                <div *ngSwitchCase="'textarea'" class="blue">
                                    <app-textarea [control]="control" [index]="i"></app-textarea>
                                </div>

                                <div *ngSwitchCase="'toggle'" class="blue">
                                    <label> {{control.label}} <span *ngIf="control.required">*</span></label>
                                    <div class="form-group">
                                        <app-toggle [control]="control" [index]="i"></app-toggle>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="card-footer">
                            <button type="submit" class="btn btn-primary pull-right">Register</button>
                            <div id="registerLink">
                                <a href="{{tenantId}}/login/{{token}}" routerLinkActive="router-link-active">Already
                                    have an account? Login here</a>
                            </div>
                        </div>

                    </div>
                </div>
            </form>

            <form name="addToTenantForm" *ngIf="alreadyRegistered !== undefined && alreadyRegistered && !confirmEmailSent"
                (ngSubmit)="submitTenant()" #addToTenantForm="ngForm">
                <div class="card card-theme text-left" *ngIf="!isOldBrowser">
                    <div class="card-header with-border">
                        <h1 class="card-title">Register for {{lookupTenant.tenantName}}</h1>
                    </div>

                    <div class="card-body">
                        <p>Click register to be added to {{lookupTenant.tenantName}}'s ticketing portal. </p>
                        <div class="alert-danger alert mb20" *ngIf="message">
                            <div class="notification-content" [innerHtml]="message">
                            </div>
                        </div>
                    </div>

                    <div class="card-footer">
                        <!-- <a href="" ng-click="vm.submitTenant()" class="btn btn-primary pull-right">Register</a> -->
                        <button type="submit" class="btn btn-primary pull-right">Register</button>
                    </div>
                </div>
            </form>
            <div class="col-sm-6 offset-sm-3 col-xs-10 offset-xs-1" *ngIf="(!alreadyRegistered) && confirmEmailSent && message">
                <div class="card card-success card-solid">
                    <div class="card-header with-border">
                        <h1 class="card-title"><i class="fa fa-check"></i> Success</h1>
                    </div>
                    <div class="card-body">
                        <p>{{message}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="registration-footer">
        <div class="pull-right hidden-xs">
            <b>Version</b> {{version}}
        </div>
        <strong>Copyright © Science Based Nutrition.</strong> All rights reserved.
    </footer>
</div>
