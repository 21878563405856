import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SuServiceService {
  path: string;

  constructor(private http: HttpClient, location: Location) {
    this.path = location.prepareExternalUrl('/api');
  }

  getAllTenants() {
    return this.http.get(`${this.path}/su/tenants`);
  }
  getAllSettings() {
    return this.http.get(`${this.path}/su/settings`);
  }

  addNewTenant(tenant) {
    return this.http.post(`${this.path}/su/tenant`, tenant);
  }

  updateTenant(tenant) {
    return this.http.put(`${this.path}/su/${  tenant.id  }/tenant`, tenant);
  }

  getAllSuperUsers(){
    return this.http.get(`${this.path}/su/superusers`);
  }

  revokeSuperUser(id){
    return this.http.delete(`${this.path}/su/superusers/${  id}`);
  }

  addSuperUser(email){
    return this.http.post(`${this.path}/su/superusers`, {email});
  }

  // Mass-send forgot password email to all tenant users
  sendResetPasswordEmail(tenantId) {
    return this.http.post(`${this.path}/su/resetpasswordemail`, {tenantId});
  }

  // get logs
  getLogs(){
    return this.http.get(`${this.path}/su/log`, {responseType: 'text'});
  }

  updateSetting(setting){
    return this.http.put(`${this.path}/su/updatesetting`, setting);
  }

}
