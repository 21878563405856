<div class="col-sm-12" style="padding: 0;">
    <div class="model-header">
        <h1>
            <button type="button" class="close outline-none" (click)="onDismiss()">&times;</button>
            Change Password
        </h1>
    </div>
    <div class="model-body" [formGroup]="changepasswordForm">
        <div class="alert-danger alert mb20" *ngIf="changePasswordFormErrors.newpassword 
        || changePasswordFormErrors.confirmpassword || changePasswordFormErrors.passwordGroup">
            <div class="notification-content" *ngIf="changePasswordFormErrors.newpassword 
                            || changePasswordFormErrors.confirmpassword || changePasswordFormErrors.passwordGroup">
                {{changePasswordFormErrors.newpassword}}
                {{changePasswordFormErrors.confirmpassword?changePasswordFormErrors.confirmpassword:changePasswordFormErrors.passwordGroup}}
            </div>
        </div>
        <div formGroupName="passwordGroup">
            <form-field>
                <label>New Password:</label>
                <input matInput type="password" class="form-control" required formControlName="newpassword"
                    id="newpassword">
            </form-field>
            <br>
            <form-field>
                <label>Confirm Password:</label>
                <input matInput type="password" class="form-control" required formControlName="confirmpassword"
                    id="confirmpassword">
            </form-field>
        </div>
    </div>
    <div class="model-footer text-right">
        <button class="btn btn-primary" (click)="onConfirm()">Change Password</button>
        <button class="btn btn-default" (click)="onDismiss()">Cancel</button>

    </div>
</div>