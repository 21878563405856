<div class="form-group">
    <label [ngClass]="{ 'invalid': formsubmitted && selectcomp.invalid }">{{control.label}} <span *ngIf="control.required">*</span>
        <i *ngIf="control.showToolTip === true" class="fa fa-question-circle helptext_toggle" data-toggle="tooltip" data-placement="top" title="{{control.helpText}}" aria-hidden="true"></i>
    </label>

    <select class="form-control" name="{{control.name}}_{{index}}" [(ngModel)]="control.value" [required]="control.required" 
        #selectcomp="ngModel" [ngClass]="{ 'invalid': formsubmitted && selectcomp.invalid }">
        <option *ngFor="let option of control.options" [value]="option">{{option}}</option>
    </select>
    <span  *ngIf="control.showHelpText === true" class="helptext" >{{control.helpText}} </span>
    <div *ngIf="formsubmitted && selectcomp.invalid " [ngClass]="{ 'invalid': formsubmitted && selectcomp.invalid }">
        {{control.label}} is required
    </div>
</div>
