<div class="form-group">
    <label for="options" [ngClass]="{ 'invalid': formsubmitted && control.required && control.value.length <= 0 }">
        {{control.label}} <span *ngIf="control.required">*</span>
        <i *ngIf="control.showToolTip === true" class="fa fa-question-circle helptext_toggle" data-toggle="tooltip" data-placement="top" title="{{control.helpText}}" aria-hidden="true"></i>
    </label>
    <input hidden name="checklist__{{index}}" [(ngModel)]="selectedvalue" />
    <div  class="checkbox" *ngFor="let option of control.options;let child_i=index;" >
        <label [ngClass]="{ 'invalid': formsubmitted && control.required && control.value.length <= 0 }">
            <input name="{{control.name}}_{{index}}" type="checkbox" required="control.required" checklist-model="control.value" [checked]="selectedvalue.includes(option)" checklist-value="option" 
                (change)="onChange(option, $event.target.checked)"> {{option}}
        </label>
    </div>
    <span  *ngIf="control.showHelpText === true" class="helptext" >{{control.helpText}} </span>
    <div *ngIf="formsubmitted && control.required && control.value.length <= 0 "
        [ngClass]="{ 'invalid': formsubmitted && control.required && control.value.length <= 0 }">
        {{control.label}} is required
    </div>
</div>