<div class="form-group">
  <label [ngClass]="{ 'invalid': formsubmitted && control.required && !control.value }"> {{control.label}} <span
      *ngIf="control.required">*</span>
      <i *ngIf="control.showToolTip === true" class="fa fa-question-circle helptext_toggle"
      data-toggle="tooltip" data-placement="top" title="{{control.helpText}}" aria-hidden="true"></i>
    </label>

    <input matInput [matDatepicker]="picker" class="form-control" name="{{control.name}}_{{index}}"
      (dateChange)="addEvent('change', $event)" [required]="control.required" #datepickercomp="ngModel"
      placeholder="{{control.label}}" [(ngModel)]="control.value" [max]="maxDate" 
      [ngClass]="{ 'is-invalid': formsubmitted && control.required && !control.value }">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker disabled="false"></mat-datepicker>
    <mat-error *ngIf="datepickercomp.hasError('matDatepickerMax')">Year is out of range</mat-error>
  <span  *ngIf="control.showHelpText === true" class="helptext" >{{control.helpText}} </span>
  <div *ngIf="formsubmitted && control.required && !control.value "
    [ngClass]="{ 'invalid': formsubmitted && control.required && !control.value }">
    {{control.label}} is required
  </div>
</div>