<div id="edit-role" class="EditRole">
    <section class="content">
        <div class="row">
            <div class="col-md-10">
                <div class="card card-theme">
                    <div class="card-header with-border">
                        <h3 class="card-title"> Edit Role {{roleName}}</h3>
                    </div>
                    <div class="card-body">
                        <form name="manageRolesForm" (ngSubmit)="saveRoles(manageRolesForm)" #manageRolesForm="ngForm">
                            <div class="form-group">
                                <label>
                                    Role Name*</label>
                                <input type="text" [(ngModel)]="roleName" name="name" class="form-control"
                                    #roleNameControl="ngModel" required trim="blur"
                                    oninput="this.value = this.value.replace(/[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}|^ /g,'')" />
                                <span style="color:red" class="help-block"
                                    *ngIf="roleNameControl.errors?.required && roleNameControl.touched">
                                    Name is Required
                                </span>
                            </div>
                            <hr>
                            <h4>Permissions</h4>
                            <div class="form-group row">
                                <div class="col-md-6 mb10">
                                    <label class="col-md-12 col-form-label text-md-left">Can
                                        administrate
                                        workspace:</label>
                                    <span class="toggle-switch" *ngIf=!readonly>
                                        <app-toggle [isChecked]=canViewAdminPages
                                            (notifyChanged)="onNotifyCanAdministrateWorkspaceChange($event)">
                                        </app-toggle>
                                    </span>
                                    <span class="toggle-switch" *ngIf="canViewAdminPages && readonly">
                                        <app-toggle [isChecked]=canViewAdminPages [isDisabled]=true
                                            (notifyChanged)="onNotifyCanAdministrateWorkspaceChange($event)">
                                        </app-toggle>
                                        <span class="knob"><i class="fa fa-lock"></i></span>
                                    </span>
                                </div>
                                <div class="col-md-6 mb10">
                                    <label class="col-md-12 col-form-label text-md-left">Can edit
                                        administrative
                                        notes:</label>
                                    <app-toggle [isChecked]=canEditNotes
                                        (notifyChanged)="onNotifyCanEditAdminNotesChange($event)">
                                    </app-toggle>
                                </div>
                                <div class="col-md-6 mb10">
                                    <label class="col-md-12 col-form-label text-md-left">Can access
                                        all tickets and
                                        internal use fields:</label>
                                    <span class="toggle-switch" *ngIf=!canViewAdminPages>
                                        <app-toggle [isChecked]=canManageTickets
                                            (notifyChanged)="onNotifyCanAccessAllTicketsChange($event)">
                                        </app-toggle>
                                    </span>
                                    <span class="toggle-switch" *ngIf=canViewAdminPages>
                                        <app-toggle [isChecked]=true [isDisabled]=true
                                            (notifyChanged)="onNotifyCanAccessAllTicketsChange($event)">
                                        </app-toggle>
                                        <span class="knob"><i class="fa fa-lock"></i></span>
                                    </span>
                                </div>
                                <div class="col-md-6 mb10">
                                    <label class="col-md-12 col-form-label text-md-left">Can view
                                        administrative
                                        notes:</label>
                                    <span class="toggle-switch" *ngIf=!canEditNotes>
                                        <app-toggle [isChecked]=canViewNotes
                                            (notifyChanged)="onNotifyCanViewAdminNotesChange($event)">
                                        </app-toggle>
                                    </span>
                                    <span class="toggle-switch" *ngIf=canEditNotes>
                                        <app-toggle [isChecked]=true [isDisabled]=true
                                            (notifyChanged)="onNotifyCanViewAdminNotesChange($event)">
                                        </app-toggle>
                                        <span class="knob"><i class="fa fa-lock"></i></span>
                                    </span>
                                </div>
                                <div class="col-md-6 mb10">
                                    <label  class="col-md-12 col-form-label text-md-left">Can edit ticket details: </label>
                                    <span class="toggle-switch" *ngIf="!canViewAdminPages && !canManageTickets">
                                        <app-toggle [isChecked]=canEditTickets
                                            (notifyChanged)="onNotifyCanEditTicketsChange($event)">
                                        </app-toggle>
                                    </span>
                                    <span class="toggle-switch" *ngIf="canViewAdminPages || canManageTickets">
                                        <app-toggle [isChecked]=true [isDisabled]=true
                                            (notifyChanged)="onNotifyCanEditTicketsChange($event)">
                                        </app-toggle>
                                        <span class="knob"><i class="fa fa-lock"></i></span>
                                    </span>
                                </div>
                            </div>
                            <hr>
                            <h3 class="tm10" style="font-weight: bold;">Form Access</h3>
                            <div class="card-body table-responsive">
                                <table class="table table-hover">
                                    <tbody>
                                        <tr>
                                            <th>Form</th>
                                            <th title="Can view and create tickets">Can View/Create ?
                                            </th>
                                            <th>Can be Assigned ?</th>
                                            <th>Notification Defaults</th>
                                            <th class="text-center" *ngIf="tenantEmailNotifications">
                                                Email</th>
                                            <th class="text-center">In App</th>
                                        </tr>

                                        <ng-container *ngFor="let form of formPermissions; let i = index">
                                            <tr>
                                                <td [attr.rowspan]="(canEditNotes || canViewNotes) ? 5 : 4"
                                                    class="center-role">
                                                    {{form.name}}
                                                </td>
                                                <td [attr.rowspan]="(canEditNotes || canViewNotes) ? 5 : 4"
                                                    class="center-role">
                                                    <span *ngIf=!canViewAdminPages>
                                                        <app-toggle [isChecked]=form.canCreateTicket
                                                            (click)="makeformDirty()"
                                                            (notifyChanged)="form.canCreateTicket=$event">
                                                        </app-toggle>
                                                    </span>
                                                    <span *ngIf=canViewAdminPages>
                                                        <app-toggle [isChecked]=true [isDisabled]=true>
                                                            (click)="makeformDirty()"
                                                        </app-toggle>
                                                        <span class="knob"><i class="fa fa-lock"></i></span>
                                                    </span>
                                                </td>
                                                <td [attr.rowspan]="(canEditNotes || canViewNotes) ? 5 : 4"
                                                    class="center-role">
                                                    <span *ngIf="form.canCreateTicket || canViewAdminPages">
                                                        <app-toggle [isChecked]=form.canBeAssigned
                                                            (click)="makeformDirty()"
                                                            (notifyChanged)="form.canBeAssigned=$event">
                                                        </app-toggle>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="p0">New Comment</td>


                                                <td class="p0 text-center" *ngIf="tenantEmailNotifications">
                                                    <input *ngIf="form.canCreateTicket || canViewAdminPages"
                                                        (click)="makeformDirty()" type="checkbox"
                                                        [checked]="form.notifications.commentEmail"
                                                        (change)="form.notifications.commentEmail = !form.notifications.commentEmail">
                                                </td>
                                                <td class="p0 text-center"><input
                                                        *ngIf="form.canCreateTicket || canViewAdminPages"
                                                        type="checkbox" name="commentNotify" (click)="makeformDirty()"
                                                        [checked]="form.notifications.commentNotify"
                                                        (change)="form.notifications.commentNotify = !form.notifications.commentNotify">
                                                </td>
                                            </tr>
                                            <tr *ngIf="canViewNotes || canEditNotes">
                                                <td class="p0">New Admin Note</td>
                                                <td class="p0 text-center" *ngIf="tenantEmailNotifications">
                                                    <input type="checkbox"
                                                        *ngIf="form.canCreateTicket || canViewAdminPages"
                                                        name="noteEmail_{{ form.id }}" (click)="makeformDirty()"
                                                        [checked]=form.notifications.noteEmail
                                                        (change)="form.notifications.noteEmail = !form.notifications.noteEmail">
                                                </td>

                                                <td class="p0 text-center"><input
                                                        *ngIf="form.canCreateTicket || canViewAdminPages"
                                                        type="checkbox" name="noteNotify_{{ form.id }}"
                                                        [checked]=form.notifications.noteNotify
                                                        (click)="makeformDirty()"
                                                        (change)="form.notifications.noteNotify = !form.notifications.noteNotify">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="p0">On Update</td>
                                                <td class="p0 text-center" *ngIf="tenantEmailNotifications">
                                                    <input *ngIf="form.canCreateTicket || canViewAdminPages"
                                                        type="checkbox" name="updateEmail_{{ form.id }}"
                                                        [checked]=form.notifications.updateEmail
                                                        (click)="makeformDirty()"
                                                        (change)="form.notifications.updateEmail=!form.notifications.updateEmail">
                                                </td>
                                                <td class="p0 text-center"><input
                                                        *ngIf="form.canCreateTicket || canViewAdminPages"
                                                        type="checkbox" name="updateNotify_{{ form.id }}"
                                                        [checked]=form.notifications.updateNotify
                                                        (click)="makeformDirty()"
                                                        (change)="form.notifications.updateNotify=!form.notifications.updateNotify">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="p0">On Assigned</td>
                                                <td class="p0 text-center" *ngIf="tenantEmailNotifications">
                                                    <input *ngIf="form.canCreateTicket || canViewAdminPages"
                                                        type="checkbox" name="newEmail_{{ form.id }}"
                                                        [checked]=form.notifications.newEmail (click)="makeformDirty()"
                                                        (change)="form.notifications.newEmail=!form.notifications.newEmail">
                                                </td>
                                                <td class="p0 text-center"><input
                                                        *ngIf="form.canCreateTicket || canViewAdminPages"
                                                        type="checkbox" name="newNotify_{{ form.id }}"
                                                        [checked]=form.notifications.newNotify (click)="makeformDirty()"
                                                        (change)="form.notifications.newNotify=!form.notifications.newNotify">
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary pull-right m10">Save</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>