import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CacheServiceService} from 'src/app/services/cache-service/cache-service.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class RoleServiceService {
  path: string;

  constructor(private http: HttpClient, private cacheService: CacheServiceService, location: Location) {
    this.path = location.prepareExternalUrl('/api');
  }

  getRole(tenantId, roleId) {
    return this.http.get(`${this.path}/${tenantId}/roles/${roleId}`);
  }

  getDefaultPermissionAccess(tenantId) {
    return this.http.get(`${this.path}/${tenantId}/defaultpermissionaccess`);
  }

  updateRole(role) {
    this.cacheService.remove(`${this.path}/${role.tenant_id}/lookup`);
    return this.http.put(`${this.path}/${role.tenant_id}/roles/${role.id}`, role);
  }

  setDefaultRole(role){
    this.cacheService.remove(`${this.path}/${role.tenant_id}/lookup`);
    return this.http.put(`${this.path}/${role.tenant_id}/roles/${role.id}/default`, role);
  }

  addNewRole = function(tenantId, role){
    this.cacheService.remove(`${this.path}/${tenantId}/lookup`);
    return this.http.post(`${this.path}/${tenantId}/roles/add`, role);
  };

  removeRole = function(tenantId, roleId){
    this.cacheService.remove(`${this.path}/${tenantId}/lookup`);
    return this.http.delete(`${this.path}/${tenantId}/roles/${roleId}`);
  };

}
