
<div class="signature-pad">
  <div class="signature-pad--body">
    <p>Sign Here : </p>
    <canvas #sPad width="900" height="600" style="touch-action: none;"></canvas>
  </div>
</div>
<br/>
<section class="container-fluid">
    <div class="row">
      <div class="col-6">
        <button class="btn btn-block btn-danger" (click)="clear()">Clear</button>
      </div>
      <div class="col-6">
        <button class="btn btn-block btn-success" (click)="save()">Save</button>
      </div>
    </div>
</section> 
