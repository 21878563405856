<!-- Navbar -->
<nav class="main-header navbar navbar-expand navbar-dark navbar-lightblue ml-0">
  <div class="dropdown">
    <a href="/{{tenantId}}/dashboard" routerLinkActive="router-link-active"
      class="brand-link logo" *ngIf="tenants.length <= 1">
      <span class="brand-text logo-lg text-white font-weight-bold">SBNTicket</span>
    </a>
    <a class="brand-link logo dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"  *ngIf="tenants.length > 1"
      aria-haspopup="true" aria-expanded="false">
      <span class="brand-text logo-lg">SBNTicket</span>
    </a>
    <div class="dropdown-menu suworkspacedropdown" aria-labelledby="dropdownMenuButton" *ngIf="tenants.length > 1">
      <ul>
        Choose a Workspace:
        <li *ngFor="let tenant of tenants">
          <a class="dropdown-item  name-header" (click)="changeTenant(tenant)">{{tenant.name}}</a>
        </li>
      </ul>

    </div>
  </div>

  <!-- Right navbar links -->
  <ul class="navbar-nav ml-auto">
    <!-- Notifications Dropdown Menu -->
    <li class="nav-item dropdown" *ngIf="notifications.length">
      <a class="nav-link" data-toggle="dropdown" href="#">
        <i class="far fa-bell"></i>
        <span class="badge badge-warning navbar-badge">{{notifications.length}}</span>
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span class="dropdown-item dropdown-header">You have {{notifications.length || 'no'}} notifications</span>
        <div class="dropdown-divider"></div>
        <div class="media">
          <div class="media-body dropdown-item" *ngFor="let notification of notifications">
            <a href="{{notification.url}}" title="{{notification.message}}">
              <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> {{notification.created_at | dateAgo}}
                <span class="float-right text-sm text-danger"><i class="fas fa-star"></i></span>
              </p>
              <p class="text-sm"><i class="fa {{notification.icon}}">{{notification.message}}</i></p>
            </a>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item dropdown-footer">Clear All </a>
      </div>
    </li>

    <li class="nav-item dropdown user user-menu ">
      <a class="nav-link" data-toggle="dropdown" href="#">
        <img src="{{image}}" class="user-image" alt="" />
        <span class="hidden-xs">{{name}}</span>
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <div class="dropdown-divider"></div>
        <a *ngIf="su" href="javascript:void(0)" [routerLink]="['/su-dashboard']" routerLinkActive="router-link-active"
          class="dropdown-item">
          SU Dashboard
        </a>
        <div class="dropdown-divider"></div>
        <a href="javascript:void(0)" [routerLink]="['/',tenantId,'profile']" routerLinkActive="router-link-active"
          class="dropdown-item">
          Edit Profile
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" [routerLink]="['/logout']" class="dropdown-item">
          Sign Out
        </a>
      </div>
    </li>

    <li class="nav-item"><a class="nav-link" href="http://help.getsmartticket.com/wordpress/" target="_blank"><i
          class="fa fa-question-circle"></i></a>
    </li>
    <li *ngIf="!isAuth" class="nav-item"><a class="nav-link" href="/login">Log in</a></li>
  </ul>
</nav>
<!-- /.navbar -->
