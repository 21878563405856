import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CacheServiceService} from 'src/app/services/cache-service/cache-service.service';
import { Location } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class StatusServiceService {
  path: string;

  constructor(private http: HttpClient, private cacheService: CacheServiceService, location: Location) {
    this.path = location.prepareExternalUrl('/api');
  }

  getTypes(tenantId){
    return this.http.get(`${this.path}/${tenantId}/status`);
  }

  create = function(statusType){
    this.cacheService.remove(`${this.path}/${statusType.tenantId}/lookup`);
    return this.http.post(`${this.path}/${statusType.tenantId}/status/`, statusType);
  };

  save(tenantId, statusTypes){
    this.cacheService.remove(`${this.path}/${tenantId}/lookup`);
    return this.http.put(`${this.path}/${tenantId}/status/`, statusTypes);
  }

  moveTickets(tenantId, formID, oldId, newId){
    return this.http.put(`${this.path}/${tenantId}/status/${oldId}/bulkMove`, {formId: formID, newStatus: newId});
  }

  delete = function(tenantId, formId,  oldId, newId){
    this.cacheService.remove(`${this.path}/${tenantId}/lookup`);
    return this.http.delete(`${this.path}/${tenantId}/status/${oldId}?newStatus=${newId}&formId=${formId}`);
  };

}
