<div class="form-group">
    <label [ngClass]="{ 'invalid': formsubmitted && control.required && control.value === '' }"> {{control.label}}
        <span *ngIf="control.required">*</span>
        <i *ngIf="control.showToolTip === true" class="fa fa-question-circle helptext_toggle" data-toggle="tooltip" data-placement="top" title="{{control.helpText}}" aria-hidden="true"></i>
    </label>
    <div>
        <input name="{{control.name}}_{{index}}" [(ngModel)]="timeval" [ngxTimepicker]="toggleTimepicker"  class="form-control" [disableClick]="false" style="margin-bottom: .5em;" [value]="timeval"
            [title]="control.showHelpText ? control.helpText : ''" readonly [ngClass]="{ 'invalid': formsubmitted && control.required && control.value === '' }">
        <ngx-material-timepicker-toggle [for]="toggleTimepicker"></ngx-material-timepicker-toggle>
        <ngx-material-timepicker #toggleTimepicker (timeSet)="setTime($event)"></ngx-material-timepicker>
    </div>
    <span  *ngIf="control.showHelpText === true" class="helptext" >{{control.helpText}} </span>
    <div *ngIf="formsubmitted && control.required && control.value === '' " [ngClass]="{ 'invalid': formsubmitted && control.required && control.value === '' }">
        {{control.label}} is required
    </div>
</div>
