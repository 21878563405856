<div class="form-group">
    <label for="options" [ngClass]="{ 'invalid': formsubmitted && control.required && control.value === '' }">
        {{control.label}}  <span *ngIf="control.required">*</span>
        <i *ngIf="control.showToolTip === true" class="fa fa-question-circle helptext_toggle" data-toggle="tooltip" data-placement="top" title="{{control.helpText}}" aria-hidden="true"></i>
    </label>
    <div class="radio" *ngFor="let option of control.options;">
        <label [ngClass]="{ 'invalid': formsubmitted && control.required && control.value === '' }">
            <input name="{{control.name}}_{{index}}" type="radio" required="control.required" value="{{option}}" [(ngModel)]="selectedvalue"  #gender="ngModel"
                (change)="onChange(option, $event.target.checked)" > {{option}}
        </label>

    </div>
    <span  *ngIf="control.showHelpText === true" class="helptext" >{{control.helpText}} </span>
    <div *ngIf="formsubmitted && control.required && control.value === ''" [ngClass]="{ 'invalid': formsubmitted && control.required && control.value === '' }">
        Gender required.
    </div>
</div>
