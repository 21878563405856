<!--<h1>Custom Form Fields</h1>-->
<div style="padding-top: 10px;">
    <!-- left column -->
    <div class="card card-theme">
        <!-- general form elements -->
        <div class="card-header">
            <!--<h3 class="card-title"> Manage <i class="fa {{vm.form.settings.details.icon}} fa-2x mr5"></i> {{vm.form.name}}</h3>-->
            <h2 class="card-title" *ngIf="form !== undefined" > Manage {{form.name}}</h2>
        </div><!-- /.card-header -->

        <div class="card-body" *ngIf="form !== undefined">
            <div class="with-border clearfix">
                <a *ngIf="form !== undefined" href="/{{tenantId}}/admin/admin-form"
                class="btn btn-primary pull-right">Manage Forms</a>
            </div>
            <form #editForm="ngForm" name="editForm" (ngSubmit)="saveForm(editForm)">
                <div class="row">
                    <div class="col-md-12">
                        <div class="alert alert-danger mb20" *ngIf="submitted">
                            <p><i class="icon fa fa-warning" *ngIf="message"></i> {{message}}</p>
                        </div>
                    </div>
                </div>
                <div class="form-group" [class.has-error]="formName.invalid && formName.touched">
                    <label class="control-label">Edit Form Name:</label>
                    <input type="text" [(ngModel)]="form.name" name="name" #formName="ngModel" class="form-control" trim="blur"
                        oninput="this.value = this.value.replace(/[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}|^ /g,'')"
                        required />
                    <span style="color:red" class="help-block" *ngIf="formName.errors?.required && formName.touched">
                        Name is Required
                    </span>
                    <span *ngIf="formExists" class="help-block" style="color: red;"> Form with this name already
                        exists. </span>
                </div>
                <div class="form-group" [class.has-error]="autoAssignedUser.invalid && autoAssignedUser.touched">
                    <label class="control-label">Auto Assign User (new tickets will be assigned to this user
                        automatically):</label>
                    <select [(ngModel)]="form.settings.details.autoAssignedUser" name="assigneduser"
                        #autoAssignedUser="ngModel" class="form-control" required>
                        <option [ngValue]=null>(None Assigned)</option>
                        <option *ngFor="let user of assignableUsers; let i = index" [ngValue]="user.id">
                            {{user.name}} ({{user.roleName}})
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <div class="panel-group">
                        <div class="panel panel-default clearfix">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a data-toggle="collapse" href="#collapseOne" aria-expanded="true"
                                        aria-controls="collapseOne" (click)="collapseAccessView = !collapseAccessView;">
                                        {{form.name}} Access
                                        <div class="pull-right">
                                            <a *ngIf="collapseAccessView" class="fa fa-minus" data-toggle="collapse"
                                                href="#collapseOne" aria-expanded="collapseAccessView"
                                                aria-controls="collapseOne"></a>
                                            <a *ngIf="!collapseAccessView" class="fa fa-plus" data-toggle="collapse"
                                                href="#collapseOne" aria-expanded="collapseAccessView"
                                                aria-controls="collapseOne"></a>
                                            <!-- <div *ngIf="collapseAccessView" class="fa fa-minus"></div>
                                                        <div *ngIf="!collapseAccessView" class="fa fa-plus"></div> -->
                                        </div>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseOne" class="panel-collapse collapse show">
                                <div class="panel-body">
                                    <table class="table table-hover">
                                        <tbody>
                                            <tr>
                                                <th>Role</th>
                                                <th title="Can view and create tickets">Can View/Create
                                                </th>
                                                <th>Can be Assigned to ?</th>
                                            </tr>
                                            <tr *ngFor="let role of form.roles.assignedRoles">
                                                <!--[(ngClass)]="{'new-role-warning': role.isNew}">-->
                                                <td class="center-role">{{role.name}}</td>
                                                <td>
                                                    <div class="form-group">
                                                        <mat-slide-toggle style="margin: 1em;"
                                                            [isChecked]="role.canCreateTicket"
                                                            [(ngModel)]="role.canCreateTicket"
                                                            name="canCreateTicket - {{role.name}}"
                                                            *ngIf="!role.isReadOnly" [color]="color">
                                                        </mat-slide-toggle>
                                                        <mat-slide-toggle style="margin: 1em;" checked="true"
                                                            name="canCreateTicket - {{role.name}}"
                                                            *ngIf="role.isReadOnly" disabled="true" [color]="color">
                                                        </mat-slide-toggle>
                                                        <span class="knob" *ngIf="role.isReadOnly"><i
                                                                class="fa fa-lock"></i></span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-group">
                                                        <mat-slide-toggle style="margin: 1em;"
                                                            [isChecked]="role.canBeAssigned"
                                                            [(ngModel)]="role.canBeAssigned"
                                                            name="canBeAssigned - {{role.name}}"
                                                            *ngIf="role.canCreateTicket" [color]="color">
                                                        </mat-slide-toggle>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default clearfix">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse"
                                        (click)="collapseTicketAgeView = !collapseTicketAgeView" href="#collapseTwo"
                                        aria-expanded="true" aria-controls="collapseTwo">
                                        {{form.name}} Ticket Age
                                        <div class="pull-right">
                                            <a *ngIf="collapseTicketAgeView" class="fa fa-minus" data-toggle="collapse"
                                                href="#collapseTwo" aria-expanded="collapseTicketAgeView"
                                                aria-controls="collapseTwo"></a>
                                            <a *ngIf="!collapseTicketAgeView" class="fa fa-plus" data-toggle="collapse"
                                                href="#collapseTwo" aria-expanded="collapseTicketAgeView"
                                                aria-controls="collapseTwo"></a>
                                        </div>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseTwo" class="panel-collapse collapse show">
                                <div class="panel-body">
                                    <label class="control-label" style="padding-left: 10px;">Calculate ticket age
                                        starting from:</label>
                                    <div class="radio" style="padding-left: 10px;padding-top: 10px;">
                                        <label class="control-label">
                                            <input type="radio" name="calculateFrom" checked value="created_at"
                                                [(ngModel)]="form.settings.details.calculateBy">
                                            <span style="padding-left: 10px;"> Ticket was Created</span>
                                        </label>
                                    </div>
                                    <div class="radio" style="padding-left: 10px;padding-top: 10px;">
                                        <label class="control-label">
                                            <input type="radio" name="calculateFrom" value="updated_at"
                                                [(ngModel)]="form.settings.details.calculateBy">
                                            <span style="padding-left: 10px;"> Ticket was last Modified</span>
                                        </label>
                                    </div>
                                    <div class="checkbox" style="padding-left: 10px; padding-top: 10px;">
                                        <label class="control-label">
                                            <input type="checkbox" name="cbOverdue"
                                                [(ngModel)]="form.settings.details.overDue">
                                            <span style="padding-left: 10px;">Consider ticket overdue if age is
                                                greater than
                                                <input (disabled)="!form.settings.details.overDue" type="number"
                                                    name="overDueDays" style="width: 10%" placeholder="0" min="0"
                                                    max="30" [(ngModel)]="form.settings.details.overDueDays">
                                                days</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="collapse" data-toggle="collapse" aria-expanded="true"
                                        (click)="collapseIconView = !collapseIconView" href="#collapseThree"
                                        aria-controls="collapseThree">
                                        {{form.name}} Icon
                                        <div class="pull-right">
                                            <a *ngIf="collapseIconView" class="fa fa-minus" data-toggle="collapse"
                                                href="#collapseThree" aria-controls="collapseThree"></a>
                                            <a *ngIf="!collapseIconView" class="fa fa-plus" data-toggle="collapse"
                                                href="#collapseThree" aria-controls="collapseThree"></a>
                                            <!--
                                                        <i *ngIf="collapseIconView" class="fa fa-minus"></i>
                                                            <i *ngIf="!collapseIconView" class="fa fa-plus"></i> -->
                                        </div>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseThree" aria-expanded="collapseIconView"
                                class="panel-collapse collapse show">
                                <div class="panel-body">
                                    <ul class="list-unstyled clearfix text-center">
                                        <li style="display: inline-block; width: 100px; padding: 5px;"
                                            *ngFor="let icon of icons">
                                            <label
                                                style="display: block; box-shadow: 0 0 3px rgba(0,0,0,0.4); background-color: #ecf0f5;"
                                                class="clearfix full-opacity-hover">
                                                <div>
                                                    <i style="display:block; width:90px; padding-top:5px; padding-bottom:10px; float:left; height: 30px;"
                                                        class="fa {{icon}} fa-2x"></i>
                                                </div>
                                                <div>
                                                    <!-- <span style="display:block; width: 3%; padding-bottom: 5px; float: left; height: 30px;" class="{{theme.secondary}}"></span> -->
                                                </div>
                                                <input type="radio" name="icon" value="{{icon}}"
                                                    style="margin-top:10px;" [(ngModel)]="form.settings.details.icon" />
                                            </label>

                                            <!-- <p class="text-center no-margin">{{theme.name}}</p>  -->
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- panel-group -->
                </div>
                <div class="form-group clearfix actionbutons">
                    <div style="padding-right:5px;">
                        <a (click)="showDeleteConfirmation(editForm)" class="btn btn-danger pull-right m5">Delete
                            Form</a>
                    </div>
                    <div>
                        <a *ngIf="editForm.dirty" (click)="cancelChanges(editForm)"
                            class="btn btn-default pull-right m5">Cancel</a>
                    </div>
                    <div>
                        <button *ngIf="editForm.dirty" type="submit"
                            class="btn btn-primary pull-right m5">Update</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
