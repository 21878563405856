import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../authentication/auth.service';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/common/control-templates/confirm-dialog/confirm-dialog.component';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  path: string;

  constructor(private http: HttpClient, private router: Router, private authService: AuthService,
    public dialog: MatDialog, location: Location) {
    this.path = location.prepareExternalUrl('/api');
  }

  // getting roles from server
  getUser(tenantId, id) {
    return this.http.get(`${this.path}/${  tenantId  }/users/${  id}`);
  }

  // getting user using tenant id from server
  getAllUsers(tenantId) {
    return this.http.get(`${this.path}/${  tenantId  }/users`);
  }

  // getting Pendinguser using tenant id from server
  getAllPendingUsers(tenantId) {
    return this.http.get(`${this.path}/${  tenantId  }/users/pending`);
  }
  // remove user from tenanat
  removeUserFromTenant(tenantId, id) {
    return this.http.delete(`${this.path}/${  tenantId  }/users/${  id}`);
  }
  // remove pending user
  removePendingUser(tenantId, pendingUserIdToRemove) {
    return this.http.delete(`${this.path}/${  tenantId  }/pendinguser/${  pendingUserIdToRemove}`);
  }
  // resend invite
  resendInvite(tenantId, pendingUsrId) {
    return this.http.post(`${this.path}/${  tenantId  }/users/invite/resend`, { pendingUserId: pendingUsrId });
  }

  // Get Pending User
  getPendingUser(token) {
    return this.http.get(`${this.path}/pendinguser/${  token}`);
  }

  inviteUser(tenantId, user) {
    return this.http.post(`${this.path}/${  tenantId  }/users/invite`, user);
  }

  getAssignableUsers(tenantId, formId) {
    return this.http.get(`${this.path}/${  tenantId  }/users/assignable/${  formId}`);
  }

  // For updating user information
  updateUser(tenantId, user) {
    return this.http.put(`${this.path}/${  tenantId  }/users/${  user.id}`, user);
  }

  bulkImport(thisRef, file, tenantId, clearExisting, progressFn) {
    const fd = new FormData();
    fd.append('file', file);
    fd.append('clearExisting', clearExisting);


    return new Observable(observer => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${this.path}/${  tenantId  }/usersimport`, true);
      xhr.setRequestHeader('Authorization', `Bearer ${  this.authService.authentication.token}`);
      xhr.onprogress = function () {
        const lastClosingBrace = xhr.responseText.lastIndexOf('}');
        const responseLength = xhr.responseText.length
        let data = `${xhr.responseText.substring(0, lastClosingBrace + 1)  }]`;
        if ((xhr.responseText.substring(responseLength - 2, responseLength - 1) === ']')
          && (xhr.responseText.substring(0, 1) !== '[')
        ) {
          data = `[${  data}`;
        }
        progressFn(thisRef, JSON.parse(data));
      };
      xhr.onload = function (e) {
        if (xhr.status === 200) {
          observer.next(JSON.parse(xhr.response));
          observer.complete();
        }
        else {
          observer.error(xhr.response);
        }
      };
      xhr.send(fd);
    });
  }

  // Manage users
  getManagingUsers(tenantId) {
    return this.http.get(`${this.path}/${  tenantId  }/users/manageTickets`);
  }


  // form is dirty or not
  openDialog() {
    const dialogTitle = `Leave Page?`;
    const message = `You haven't saved your changes. Do you want to leave without finishing ?`;
    const okButtonText = `Leave This Page  `;
    const cancelButtonText = `Stay on This Page`;

    const dialogData = new ConfirmDialogModel(dialogTitle, message, okButtonText, cancelButtonText);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: dialogData
    });
    return dialogRef.afterClosed();
  }

}
