<div class="col-sm-12" style="padding: 0;">
    <div class="model-header">
        <h4 *ngIf="user.name !== undefined">
            <span class="close" (click)="onDismiss()">&times;</span>
            Reset Password for {{user.name}}
        </h4>
    </div>
    <div class="model-body">
      <form name="ResetPassword" (ngSubmit)="ResetPassword(ResetPasswordForm)" #ResetPasswordForm="ngForm">
              <div class="row">
                  <div class="col-md-12">
                      <div class="alert alert-danger mb20" *ngIf="submitted && message">
                          <p><i class="icon fa fa-warning" *ngIf="message"></i> {{message}}</p>
                      </div>
                      <div class="form-group" [class.has-error]="passwordControl.invalid && passwordControl.touched">
                          <label class="control-label">New Password *</label>
                          <input type="password" [(ngModel)]="password" name="password" #passwordControl="ngModel"
                              class="form-control" required password (keydown.space)="$event.preventDefault();" />
                          <span style="color:red" class="help-block"
                              *ngIf="passwordControl.errors?.required && passwordControl.touched">
                              Password is Required.
                          </span>
                      </div>
                      <div class="form-group" [class.has-error]="(confirmpasswordControl.touched && confirmpasswordControl.invalid) ||
                                                                (passwordControl.touched && confirmpasswordControl.touched && (confirmpasswordControl.value !== passwordControl.value))">
                        <label class="control-label">Confirm Password *</label>
                        <input type="password" [(ngModel)]="confirmpassword" name="confirmpassword" #confirmpasswordControl="ngModel"
                            class="form-control" required confirmpassword (keydown.space)="$event.preventDefault();" />
                        <span style="color:red" class="help-block"
                            *ngIf="confirmpasswordControl.errors?.required && confirmpasswordControl.touched">
                            Password is Required.
                        </span>
                        <span style="color:red" class="help-block"
                            *ngIf="passwordControl.touched && confirmpasswordControl.touched && (confirmpasswordControl.value !== passwordControl.value)">
                            Password and confirm password do not match.
                        </span>
                    </div>
                  </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button class="btn btn-primary" type="submit">Reset password</button>
                </div>
              </div>
      </form>
      <div>&nbsp;</div>
      <div class="row">
        <div class="col-md-12">
          <label class="control-label">Reset Link</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10">{{resetUrl}}</div>
        <div class="col-md-2">
          <button class="btn btn-sm btn-default" title="copy url" (click)="copyUrl()"><i class="fa fa-copy"></i></button>
        </div>
      </div>
      <div>
        <span [style.color]="copyStatus === 'success' ? 'green':'red'">
          {{copyMessage}}
        </span>
    </div>
    <div class="model-footer text-right">
      <button class="btn btn-default" (click)="onDismiss()">Close</button>
  </div>
</div>
