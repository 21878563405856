<!-- <div class="{{lookupTenant.tenant.tenantSettings.settings.themeColor ||  'skin-lime'}}"> -->
<div class="skin-blue">
    <div class="row-flex full-height center-xs middle-xs  bg-theme  bg-splash">
        <div class="main-header">SBNTicket</div>
        <main class="login-form">
            <div class="cotainer">
                <div class="row justify-content-center">
                    <div class="col-xs-10 col-md-6">
                        <div class="card">
                            <div class="card-header">
                                <h1>Reset Password</h1>
                            </div>
                            <div class="card-body">
                                <form [formGroup]="resetPasswordForm" (ngSubmit)="onResetPasswordSubmit()" novalidate
                                    class=" form-horizontal">
                                    <div class="alert-danger alert mb20"
                                        *ngIf="resetPasswordMessage || (resetPasswordFormErrors.password || resetPasswordFormErrors.confirmPassword)">
                                        {{resetPasswordMessage}}
                                        <div class="notification-content" *ngIf="resetPasswordFormErrors.password">
                                            {{resetPasswordFormErrors.password}}
                                        </div>
                                        <div class="notification-content"
                                            *ngIf="resetPasswordFormErrors.confirmPassword">
                                            {{resetPasswordFormErrors.confirmPassword}}
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4 col-form-label text-md-right">New Password </label>
                                        <div class="col-sm-7">
                                            <input formControlName="password" class="form-control" type="password"
                                                name="password" id="password" required />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4 col-form-label text-md-right">Confirm Password </label>
                                        <div class="col-sm-7">
                                            <input formControlName="confirmPassword" class="form-control"
                                                type="password" name="confirmPassword" id="confirmPassword" required />
                                        </div>
                                    </div>
                                    <div class="col-md-12 ">
                                        <button type="submit" class="btn btn-primary pull-right">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
