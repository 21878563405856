<!-- <h3 mat-dialog-title>
    <p [innerHTML]="title"></p>
 </h3>
  

<div mat-dialog-content [innerHTML]="message"></div>
  
<div mat-dialog-actions align="end">
    <button mat-button (click)="onDismiss()">{{cancelButtonText}}</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">{{okButtonText}}</button>
</div> -->


<div class="col-sm-12" style="padding: 0;">
    <div class="model-header">
      <h3>
        <span class="close" (click)="onDismiss()">&times;</span>
        <p [innerHTML]="title"></p>
      </h3>
    </div>
    <div class="model-body">
        <div [innerHTML]="message"></div>
    </div>
    <div class="model-footer">
        <button class="btn btn-primary" (click)="onConfirm()">{{okButtonText}}</button>
        <button class="btn btn-default" (click)="onDismiss()">{{cancelButtonText}}</button>
    </div>
  </div>