import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Notification } from '../../model/notification/notification';
import { BehaviorSubject, Observable } from 'rxjs';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AccountServiceService {
  notifications = null;
  path: string;

  public notificationsUpdated: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  constructor(private http: HttpClient, private router: Router, location: Location) {
    this.path = location.prepareExternalUrl('/api')
  }


  // setting notification Updated
  setNotificationUpdated() {
    this.notificationsUpdated.next(true);
  }

  // fetching notification Updated details
  getNotificationUpdatedStatus(): Observable<any> {
    return this.notificationsUpdated.asObservable();
  }


  getNotifications(tenantId): Observable<Notification[]> {
    return this.http.get<Notification[]>(`${this.path}/${tenantId}/account/notifications`);
  }

  // forgot password api
  forgotPassword(data) {
    console.log(data);
    return this.http.post(`${this.path}/account/forgotpassword`, data);
  }

  // Update Password API
  updatePassword(password) {
    return this.http.put(`${this.path}/account/password`, { password });
  }

  // update Account tenant
  updateAccountTenant(user, tenantId) {
    return this.http.put(`${this.path}/${tenantId}/account`, {
      name: user.name,
      email: user.email,
      user_metadata: user.user_metadata
    });
  }

  // update user tenant
  updateAccount(user) {
    return this.http.put(`${this.path}/account`, {
      name: user.name,
      email: user.email,

    });
  }

  // Getting the Dashboard details
  getDashboard(id) {
    return this.http.get(`${this.path}/account/dashboard`);
  }

  // reset password
  resetPassword(request) {
    return this.http.post(`${this.path}/account/resetpassword`, request);
  }

  // Delete Notifications
  deleteNotifications(tenantId, url) {
    if (url === true) {
      url = '';
      return this.http.delete(`${this.path}/${tenantId}/account/notifications`);
    }
    else {
      return this.http.delete(`${this.path}/${tenantId}/account/notifications?url=${encodeURIComponent(url)}`);
    }
  }

  getResetUrl(userId) {
    return this.http.get(`${this.path}/${userId}/account/reseturl`);
  }

  // reset user's password
  resetUserPassword(request) {
    return this.http.post(`${this.path}/account/resetuserpassword`, request);
  }

}
