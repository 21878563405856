<div class="col-sm-12" style="padding: 0;">
    <div class="model-header">
        <h4 *ngIf="user.name !== undefined">
            <span class="close" (click)="onDismiss()">&times;</span>
            {{user.name}} Profile
        </h4>
    </div>
    <form name="EditUserForm" (ngSubmit)="EditUser(EditUserForm)" #EditUserForm="ngForm">
        <div class="model-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="alert alert-danger mb20" *ngIf="submitted && message">
                        <p><i class="icon fa fa-warning" *ngIf="message"></i> {{message}}</p>
                    </div>
                    <div class="form-group" [class.has-error]="userEmailControl.invalid && userEmailControl.touched">
                        <label class="control-label">Login Email *</label>
                        <input type="email" [(ngModel)]="user.email" name="email" #userEmailControl="ngModel"
                            class="form-control" required email (keydown.space)="$event.preventDefault();" />
                        <span style="color:red" class="help-block"
                            *ngIf="userEmailControl.errors?.required && userEmailControl.touched">
                            Email is Required
                        </span>
                        <span style="color:red" class="help-block"
                            *ngIf="userEmailControl.errors?.email && userEmailControl.touched">
                            Valid Email Address Required
                        </span>
                    </div>
                    <div class="form-group">
                        <label>Role*</label>
                        <select [(ngModel)]="user.role_id" name="role" #userRoleControl="ngModel" class="form-control"
                            required>
                            <option *ngFor="let role of roles; let i = index" [value]="roles[i].id">
                                {{roles[i].name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div [ngClass]="{'col-sm-6': control.type !== 'textarea', 'col-sm-12':control.type === 'textarea' }"
                    *ngFor="let control of metadataControls.userControls; let i=index;" [ngSwitch]="control.type">
                    <div *ngSwitchCase="'text'" class="blue">
                        <app-text [control]="control" [index]="i"></app-text>

                    </div>

                    <div *ngSwitchCase="'label'" class="blue">
                        <app-label [control]="control" [index]="i"></app-label>

                    </div>

                    <div *ngSwitchCase="'phone number'" class="blue">
                        <app-phonenumber [control]="control" [index]="i"></app-phonenumber>
                    </div>

                    <div *ngSwitchCase="'signature'" class="blue">
                        <div class="form-group">
                            <label>{{control.label}}</label>
                            <div class="signaturediv">
                                <img class="img-responsive" src="{{control.value}}" *ngIf="control.value !== ''" />
                                <button type="button" class="btn btn-primary"
                                    (click)="signature(control , i)">Sign</button>
                            </div>

                        </div>
                    </div>

                    <div *ngSwitchCase="'email'" class="blue">
                        <app-email [control]="control" [index]="i"></app-email>
                    </div>

                    <div *ngSwitchCase="'datepicker'" class="blue">
                        <app-datepicker [control]="control" [index]="i"></app-datepicker>
                    </div>

                    <div *ngSwitchCase="'timepicker'" class="blue">
                        <app-timepicker [control]="control" [index]="i"></app-timepicker>
                    </div>

                    <div *ngSwitchCase="'radiobuttonlist'" class="blue">
                        <app-radiobuttonlist [control]="control" [index]="i"></app-radiobuttonlist>
                    </div>

                    <div *ngSwitchCase="'checkboxlist'" class="blue">
                        <app-checkboxlist [control]="control" [index]="i"></app-checkboxlist>
                      </div>

                    <div *ngSwitchCase="'select'" class="blue">
                        <app-select [control]="control" [index]="i"></app-select>
                    </div>

                    <div *ngSwitchCase="'textarea'" class="blue">
                        <app-textarea [control]="control" [index]="i"></app-textarea>
                    </div>

                    <div *ngSwitchCase="'toggle'" class="blue">
                        <app-toggle [control]="control" [index]="i"></app-toggle>
                    </div>
                </div>
            </div>
            <div *ngIf="metadataControls.internalControls && metadataControls.internalControls.length > 0">
                <hr>
                <label><i>Internal Only</i></label>
                <div class="row">
                    <div [ngClass]="{'col-sm-6': control.type !== 'textarea', 'col-sm-12':control.type === 'textarea' }"
                        *ngFor="let control of metadataControls.internalControls; let i=index;"
                        [ngSwitch]="control.type">
                        <div *ngSwitchCase="'text'" class="blue">
                            <app-text [control]="control" [index]="i"></app-text>

                        </div>
                        <div *ngSwitchCase="'label'" class="blue">
                            <app-label [control]="control" [index]="i"></app-label>

                        </div>
                        <div *ngSwitchCase="'phone number'" class="blue">
                            <app-phonenumber [control]="control" [index]="i"></app-phonenumber>
                        </div>

                        <div *ngSwitchCase="'signature'" class="blue">
                            <div class="form-group">
                                <label>{{control.label}}</label>
                                <div class="signaturediv">
                                    <img class="img-responsive" src="{{control.value}}" *ngIf="control.value !== ''" />
                                    <button type="button" class="btn btn-primary"
                                        (click)="signature(control , i)">Sign</button>
                                </div>

                            </div>
                        </div>

                        <div *ngSwitchCase="'email'" class="blue">
                            <app-email [control]="control" [index]="i"></app-email>
                        </div>

                        <div *ngSwitchCase="'datepicker'" class="blue">
                            <app-datepicker [control]="control" [index]="i"></app-datepicker>
                        </div>

                        <div *ngSwitchCase="'timepicker'" class="blue">
                            <app-timepicker [control]="control" [index]="i"></app-timepicker>
                        </div>

                        <div *ngSwitchCase="'radiobuttonlist'" class="blue">
                            <app-radiobuttonlist [control]="control" [index]="i"></app-radiobuttonlist>
                        </div>

                        <div *ngSwitchCase="'select'" class="blue">
                            <app-select [control]="control" [index]="i"></app-select>
                        </div>

                        <div *ngSwitchCase="'textarea'" class="blue">
                            <app-textarea [control]="control" [index]="i"></app-textarea>
                        </div>

                        <div *ngSwitchCase="'toggle'" class="blue">
                            <app-toggle [control]="control" [index]="i"></app-toggle>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="model-footer text-right">
            <button class="btn btn-primary" type="submit">Save changes</button>
            <button class="btn btn-default" (click)="onDismiss()">Cancel</button>
        </div>
    </form>
</div>
