<div class="tenant-grid">
    <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
      <div class="input-group">
        <div class="search" data-toggle="tooltip" title="Manage Users">
          <input type="text" class="form-control" placeholder="Filter Items"
          (keyup)="quickSearch()" [(ngModel)]="searchValue">
          <a (click)="refreshgrid()" class="form-control-feedback feedback-link">×</a>
        </div>
        <div class="dropdown" data-toggle="tooltip" title="Manage Users">
          <button class="btn btn-default dropdown-toggle" type="button"
              id="advancefilter" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <i class="fa fa-cog" aria-hidden="true"></i>
          </button>
        
          <ul class="dropdown-menu advancefilter_class" aria-labelledby="advancefilter">
              <li class="dropdown-item">
                  <a (click)="showhideallcolumns($event, true)">
                      <i class="fa fa-check" aria-hidden="true"></i>
                      <span class="name">Check All</span>
                  </a>
              </li>
              <li class="dropdown-item">
                  <a (click)="showhideallcolumns($event, false)">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="name">Uncheck All</span>
                  </a>
              </li>
              <li class="divider"></li>
              <li class="dropdown-item" *ngFor="let FilterColumns of advanceFilterColumns">
                  <a (click)="showhidecolumn($event, FilterColumns)">
                      <i [ngClass]="{'fa fa-check': FilterColumns.value === true}" aria-hidden="true"></i>
                      <span class="name">{{FilterColumns.label}}</span>
                  </a>
              </li>
          </ul>
        </div>
      </div>
      <div class="btn-group">
        <button type="button" data-toggle="tooltip" title="Manage Users" class="btn btn-primary pull-right" (click)=AddUser()>Add User</button>
      </div>
    </div>
    <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" 
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [pagination]="true" 
        [paginationPageSize]="paginationPageSized"
        [tooltipShowDelay]="tooltipShowDelay"
        (gridReady)="onGridReady($event)"
        [rowSelection]="rowSelection"
        [frameworkComponents]="frameworkComponents"
        >
    </ag-grid-angular>
  </div>