<div id="profile" class="{{lookupData.tenant.tenantSettings.settings.themeColor}} || 'skin-blue' sidebar-collapse">
    <div class="">
        <section class="content" *ngIf=user>
            <div class="row ">
                <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 offset-xl-3 offset-lg-3 offset-md-2 offset-sm-1">
                    <div class="card card-widget widget-user">

                        <div class="widget-user-header bg-theme-alt">
                            <h3 class="widget-user-username">{{user.name}}</h3>
                            <h5 class="widget-user-desc">{{user.email}}</h5>
                        </div>

                        <div class="widget-user-image">
                            <img class="img-circle" [src]="image" alt="{{user.name}}" />
                        </div>

                        <div class="card-footer pt-2">
                            <div class="row col-12">
                                <div class="col-sm-6 col-xs-6">
                                    <div class="description-block">
                                        <h5 class="description-header">{{user.created_at | date: 'M/d/yyyy'}}</h5>
                                        <span class="description-text">User Since</span>
                                    </div><!-- /.description-block -->
                                </div><!-- /.col -->
                                <div class="col-sm-6 col-xs-6">
                                    <div class="description-block">

                                        <h5 class="description-header">{{workspaces}}</h5>
                                        <span class="description-text">Workspaces</span>
                                    </div><!-- /.description-block -->
                                </div><!-- /.col -->
                            </div><!-- /.row -->
                        </div>

                    </div>
                </div>
            </div>

            <form name="profileForm" (ngSubmit)="saveProfile(profileForm)" #profileForm="ngForm">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12 offset-xl-1 pr-3">
                        <div class="card card-theme ">
                            <div class="card-header with-border">
                                <h2 class="card-title">Update Profile</h2>
                            </div>
                            <div class="card-body">
                                <div class="alert-danger alert mb20"
                                    *ngIf="userNameControl.errors || userEmailControl.errors"
                                    [ngClass]="{ 'has-error': (profileForm.invalid && profileForm.submitted) }">
                                    {{message}}
                                    <div class="notification-content" *ngIf="userNameControl.errors ">
                                        <span *ngIf="userNameControl.errors.required">Name is Required</span>
                                    </div>
                                    <div class="notification-content" *ngIf="userEmailControl.errors ">
                                        <div>
                                            <div *ngIf="userEmailControl.errors.required">Email is Required</div>
                                            <div *ngIf="userEmailControl.errors.email">Valid Email Address Required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>
                                        Name*</label>
                                    <input type="text" [(ngModel)]="user.name" name="name" class="form-control"
                                        oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]|^ /g,'')" trim="blur"
                                        #userNameControl="ngModel" required />
                                </div>
                                <div class="form-group">
                                    <label>
                                        Email* </label> <i class="text-muted pull-right"> Note: this will change your
                                        login email!</i>
                                    <input type="email" [(ngModel)]="user.email" name="email"
                                        (keypress)="removeSpaces($event)"
                                        oninput="this.value = this.value.replace(/[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}|^ /g,'')"
                                        #userEmailControl="ngModel" class="form-control" required email />
                                </div>


                                <div class="row">
                                    <div [ngClass]="{'col-sm-6': control.type !== 'textarea', 'col-sm-12':control.type === 'textarea' }"
                                        *ngFor="let control of chunkedData; let i=index;" [ngSwitch]="control.type">

                                        <div *ngSwitchCase="'signature'" class="blue">
                                            <div class="form-group">
                                                <label>{{control.label}}</label>
                                                <div class="signaturediv">
                                                    <img class="img-responsive" src="{{control.value}}" *ngIf="control.value !== ''" />
                                                    <button type="button" class="btn btn-primary"
                                                        (click)="signature(control , i)">Sign</button>
                                                </div>

                                            </div>
                                        </div>

                                        <div *ngSwitchCase="'text'" class="blue">
                                            <app-text [control]="control" [index]="i"></app-text>

                                        </div>

                                        <div *ngSwitchCase="'label'" class="blue">
                                            <app-label [control]="control" [index]="i"></app-label>

                                        </div>

                                        <div *ngSwitchCase="'phone number'" class="blue">
                                            <app-phonenumber [control]="control" [index]="i"></app-phonenumber>
                                        </div>

                                        <div *ngSwitchCase="'signaturepad'" class="blue">
                                            <app-signature-pad [control]="control" [index]="i"></app-signature-pad>
                                        </div>

                                        <div *ngSwitchCase="'email'" class="blue">
                                            <app-email [control]="control" [index]="i"></app-email>
                                        </div>

                                        <div *ngSwitchCase="'datepicker'" class="blue">
                                            <app-datepicker [control]="control" [index]="i"></app-datepicker>
                                        </div>

                                        <div *ngSwitchCase="'timepicker'" class="blue">
                                            <app-timepicker [control]="control" [index]="i"></app-timepicker>
                                        </div>

                                        <div *ngSwitchCase="'radiobuttonlist'" class="blue">
                                            <app-radiobuttonlist [control]="control" [index]="i"></app-radiobuttonlist>
                                        </div>

                                        <div *ngSwitchCase="'checkboxlist'" class="blue">
                                            <app-checkboxlist [control]="control" [index]="i"></app-checkboxlist>
                                          </div>

                                        <div *ngSwitchCase="'select'" class="blue">
                                            <app-select [control]="control" [index]="i"></app-select>
                                        </div>

                                        <div *ngSwitchCase="'textarea'" class="blue">
                                            <app-textarea [control]="control" [index]="i"></app-textarea>
                                        </div>

                                        <div *ngSwitchCase="'toggle'" class="blue">
                                            <label> {{control.label}} <span *ngIf="control.required">*</span></label>
                                            <div class="form-group">
                                                <app-toggle [control]="control" [index]="i"></app-toggle>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!-- ToDo: Add dynamic controls -->
                                <div class="card-footer">
                                    <a class="btn btn-default" (click)=ChangePassword()>Change Password...</a>
                                </div>
                            </div>
                        </div>
                        <!-- <pre>{{profileForm.value | json}}</pre> -->
                    </div>

                    <div class="col-xl-4 col-lg-6 col-md-12">
                        <div class="card card-theme">
                            <div class="card-header with-border">
                                <h2 class="card-title">Update Notifications</h2>
                            </div>
                            <table class="table">
                                <tr>
                                    <th>Form</th>
                                    <th>Notifications</th>
                                    <th class="text-center" *ngIf="showEmail">Email</th>
                                    <th class="text-center">In App</th>
                                </tr>
                                <ng-container *ngFor="let form of lookupData.tenant.forms;">
                                    <tr>
                                        <td [attr.rowspan]="canViewNotes ? 4 : 3">{{form.name}}</td>
                                        <td class="p0">New Comment</td>
                                        <td class="p0 text-center" *ngIf="showEmail">
                                            <input type="checkbox" name="commentEmail_{{ form.id }}"
                                                [checked]="user.user_metadata.notifications[form.id].commentEmail === true ? true : false"
                                                [(ngModel)]="user.user_metadata.notifications[form.id].commentEmail">
                                        </td>
                                        <td class="p0 text-center"><input type="checkbox"
                                                name="commentNotify_{{ form.id }}"
                                                [checked]="user.user_metadata.notifications[form.id].commentNotify === true ? true : false"
                                                [(ngModel)]="user.user_metadata.notifications[form.id].commentNotify">
                                        </td>
                                    </tr>
                                    <tr *ngIf="canViewNotes">
                                        <td class="p0">New Admin Note</td>
                                        <td class="p0 text-center" *ngIf="showEmail">
                                            <input type="checkbox" name="noteEmail_{{ form.id }}"
                                                [checked]="user.user_metadata.notifications[form.id].noteEmail === true ? true : false"
                                                [(ngModel)]="user.user_metadata.notifications[form.id].noteEmail "></td>
                                        <td class="p0 text-center"><input type="checkbox"
                                                name="noteNotify_{{ form.id }}"
                                                [checked]="user.user_metadata.notifications[form.id].noteNotify === true ? true : false"
                                                [(ngModel)]="user.user_metadata.notifications[form.id].noteNotify"></td>
                                    </tr>
                                    <tr>
                                        <td class="p0">On Update</td>
                                        <td class="p0 text-center" *ngIf="showEmail">
                                            <input type="checkbox" name="updateEmail_{{ form.id }}"
                                                [checked]="user.user_metadata.notifications[form.id].updateEmail === true ? true : false"
                                                [(ngModel)]="user.user_metadata.notifications[form.id].updateEmail ">
                                        </td>
                                        <td class="p0 text-center"><input type="checkbox"
                                                name="updateNotify_{{ form.id }}"
                                                [checked]="user.user_metadata.notifications[form.id].updateNotify === true ? true : false"
                                                [(ngModel)]="user.user_metadata.notifications[form.id].updateNotify">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p0">On Assigned</td>
                                        <td class="p0 text-center" *ngIf="showEmail">
                                            <input type="checkbox" name="newEmail_{{ form.id }}"
                                                [checked]="user.user_metadata.notifications[form.id].newEmail === true ? true : false"
                                                [(ngModel)]="user.user_metadata.notifications[form.id].newEmail "></td>
                                        <td class="p0 text-center"><input type="checkbox" name="newNotify_{{ form.id }}"
                                                [checked]="user.user_metadata.notifications[form.id].newNotify === true ? true : false"
                                                [(ngModel)]="user.user_metadata.notifications[form.id].newNotify"></td>
                                    </tr>
                                </ng-container>

                            </table>
                            <div class="pb5"></div>
                        </div>
                        <button type="submit" class="btn btn-primary pull-right" style="margin-bottom: 20px;">Save
                            Profile</button>
                    </div>

                </div>
            </form>

        </section>
    </div>
</div>

<app-footer class="su-footer ml-0"></app-footer>
