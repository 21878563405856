<div class="col-sm-12" style="padding: 0;">
    <div class="model-header">
        <h4>
            <button type="button" class="close outline-none" (click)="onDismiss()">&times;</button>
            Edit Status
        </h4>
    </div>
    <form name="editStatusForm" (ngSubmit)="saveChanges(editStatusForm)" #editStatusForm="ngForm">
        <div class="modal-body">
            <p>Updating names or colors will update this status on all forms</p>
            <div class="row">
                <div class="form-group" class="col-sm-6">
                    <label>Add to Workflow in {{form.name}} Form</label>
                    <div>
                        <mat-slide-toggle [(ngModel)]="status.isWorkFlow" name="workflowControl" #workFlowControl="ngModel"
                        color="primary"></mat-slide-toggle>
                    </div>
                </div>
                <div class="form-group" class="col-sm-6">
                    <app-color-picker [color]="status.color" [heading]="'Color'" (event)="setColor($event)"
                        [defaultColors]="colorOptions.palette" [ShowInputText]="false"></app-color-picker>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="form-group" class="col-sm-12">
                    <label>Name*</label>
                    <input name="statusName" type="text" #nameControl="ngModel" class="form-control" trim="blur"
                        [(ngModel)]="status.name" maxLength="25" required
                        oninput="this.value = this.value.replace(/[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}|^ /g,'')">
                    <i>Action Label: {{status.name}}</i>
                </div>
                <div>
                </div>
            </div>
        </div>
        <div class="model-footer text-right">
            <button class="btn btn-primary" type="submit">Save</button>
            <button class="btn btn-default" (click)="onDismiss()">Cancel</button>     
        </div>
    </form>
</div>