import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CacheServiceService} from 'src/app/services/cache-service/cache-service.service';
import { Location } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class FormServiceService {
  path: string;

  constructor(private http: HttpClient, private router: Router,
              private cacheService: CacheServiceService, location: Location) {
    this.path = location.prepareExternalUrl('/api');
  }

  getFormIcons() {
    return this.http.get(`/api/forms/icons`);
  }

  save(tenantId, form){
    delete form.namePlural;
    this.cacheService.remove(`${this.path}/${tenantId}/lookup`);
    this.cacheService.remove(`${this.path}/${tenantId}/form/${  form.id}`);
    return this.http.put(`${this.path}/${tenantId}/form/${form.id}`, form);
  }

  // save the form order
  saveFormOrder(tenantId, formIds){
    // eslint-disable-next-line object-shorthand
    return this.http.post(`${this.path}/${tenantId}/form-order/`, {formIds: formIds});
  }

  // delete form
  delete(tenantId, formId){
    return this.http.delete(`${this.path}/${tenantId}/form/${  formId}` );
  }

  saveForm(tenantId, form) {
    return this.http.put(`${this.path}/${tenantId}/form/${  form.id}`, form);
  }

  create(tenantId, form){
    this.cacheService.remove(`${this.path}/${tenantId}/lookup`);
    return this.http.post(`${this.path}/${tenantId}/form/`, form);
  }
}
