import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ResponseTemplateServiceService {
  path: string;

  constructor(private http: HttpClient, location: Location) {
    this.path = location.prepareExternalUrl('/api');
  }

  get(tenantId, formId){
    return this.http.get(`${this.path}/${tenantId}/form/${formId}/templates`);
  }

  update(tenantId, formId, responseTemplates){
    return this.http.put(`${this.path}/${tenantId}/form/${formId}/templates`, responseTemplates);
  }

  add(tenantId, formId, responseTemplate){
    return this.http.post(`${this.path}/${tenantId}/form/${formId}/templates`, responseTemplate);
  }

}
